.login-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Onest", sans-serif;
}

.login-wrapper {
  width: 100%;
  max-width: 1000px;
  height: 75vh;
  max-height: 700px;
  margin: auto;
  display: flex;
  font-weight: 400;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.login-page .left-content {
  background: url(../../assets/login2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.login-wrapper .left-content,
.login-wrapper .right-content {
  flex: 1;
  padding: 80px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-content h2 {
  letter-spacing: 1px;
  margin-bottom: 5px;
  font-size: 30px;
  font-weight: 500;
}

.left-content .content {
  padding: 20px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
}

.left-content .content > p {
  font-weight: 400;
  margin-bottom: 20px;
}

.register {
  margin: 20px 0;
  font-size: 0.9em;
}

.register > p > span {
  color: rgb(119, 153, 255);
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .login-wrapper .left-content {
    display: none;
  }
}
