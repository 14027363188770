.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 999;
  background: transparent;
}

.nav .logo {
  width: 20%;
}

.nav .logo img {
  width: 45px;
  height: 45px;
  cursor: pointer;
}

.nav .nav-items {
  width: 80%;
  display: flex;
  justify-content: flex-end;
  list-style: none;
  font-size: 15px;
}

.nav-items .item {
  padding: 4px 6px;
  border-radius: 3px;
  border: 1px solid transparent;
  color: #eee;
}

.nav-items.scroll .item {
  color: #333;
}

.nav-items .item:hover {
  border: 1px solid #ddd;
}

.nav-items .item.ant-dropdown-trigger {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.nav-items .item.ant-dropdown-trigger .ant-dropdown {
  z-index: 3;
}

.nav-items .item:not(.ant-dropdown-trigger) a {
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav .burger-menu {
  display: none;
}

@media screen and (min-width: 901px) and (max-width: 1000px) {
  .nav .nav-items {
    font-size: 13px;
  }
}

@media screen and (max-width: 900px) {
  .nav .nav-items {
    display: none;
  }

  .auth-btn {
    font-size: 14px;
    padding: 2px 10px;
    height: 26px;
    display: flex;
    align-items: center;
  }

  .nav .burger-menu {
    display: block;
    position: absolute;
    top: 14px;
    right: 12px;
    z-index: 3;
    color: var(--darkblue2);
    font-size: 30px;
    cursor: pointer;
    padding: 0 6px;
    border-radius: 3px;
  }
}
