.detail-corporate {
  width: 600px;
  max-width: 100%;
}

.detail-corporate > p {
  font-size: larger;
  margin: 20px 0;
  padding-bottom: 7px;
  border-bottom: 1px solid var(--gray1);
}

.detail-corporate img {
  border: 1px solid var(--gray1);
}

.detail-corporate table {
  width: 1000px;
  max-width: 100%;
}

.detail-corporate table tbody tr td {
  border-bottom: 1px solid var(--gray1);
  padding: 10px;
}

.detail-corporate table tbody tr td:first-child {
  white-space: nowrap;
}
