@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&family=Onest:wght@300;400;500;600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* background */
  --blue: #1677ff;
  --darkblue: #142433;
  --darkblue2: #1e4258;
  --darkblue3: #295c7b;
  --gray1: #eee;
  --gray2: #ddd;
  --gray3: #ccc;
  --gray4: #bbb;
  --gray5: #aaa;
  --gray6: #999;

  /* font */
  --font-primary-dark: #123b55;
  --font-secondary-dark: #777;
  --font-primary-light: #fff;
  --font-secondary-light: #ddd;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Onest", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3 {
  font-family: "Onest", sans-serif;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}

/* page */
.page-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.table-filter {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.table-filter .filter {
  width: 200px;
  max-width: 100%;
}

.table-filter.large {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  max-width: 1200px;
  margin: 50px auto;
}

.table-filter.large .filter {
  width: 350px;
  max-width: 100%;
}

.table-filter.large .filter .ant-picker {
  padding: 0 10px;
}

.table-filter.large .filter input {
  padding: 10px 30px;
}

.table-filter.large .filter input::placeholder {
  color: var(--gray5);
  font-size: 16px;
}

@media screen and (max-width: 810px) {
}
