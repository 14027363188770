.show-more-button {
  padding: 20px 25px;
  border-radius: 25px;
  background: transparent;
  outline: none;
  border: 1px solid var(--font-primary-dark);
  color: var(--font-primary-dark);
  font-family: "Onest", sans-serif;
  font-size: 16px;
  cursor: pointer;
  font-weight: 500;
  margin-top: 30px;
}
.show-more-button:hover {
  background: var(--blue);
  transition: 0.3s;
  color: #fff !important;
  border-color: #fff;
}

.show-more-button.light {
  border: 1px solid #fff;
  color: #fff;
}

.mobile-menu-wrapper {
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
}
