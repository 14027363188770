/* ! this css for custom ant design components */

.ant-card.ant-card-hoverable {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

button.ant-btn-primary {
  background: var(--blue);
}

button.secondary-button {
  background: #bfcfe7;
}

button.btn-login {
  background: transparent;
  border: 2px solid #fff;
}
button.btn-login:hover {
  border-color: transparent;
}
button.btn-larger {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px !important;
  text-transform: uppercase;
}

/* layout */
.ant-layout-sider {
  width: 250px;
  border-right: 1px solid #eaeaea;
  position: relative;
  background: var(--darkblue) !important;
}

.ant-layout-sider .logo {
  font-size: 1.5em;
  font-weight: 500;
  height: 64px;
  margin: 0 10px;
  border-bottom: 1px solid #444;
  box-sizing: border-box;
  color: #eee;

  display: flex;
  justify-content: center;
  align-items: center;
}

.logo.small {
  font-size: 1.1em;
  margin: 0px;
}

.trigger-button {
  position: absolute;
  top: 50px;
  right: -10px;
  font-size: 1.8em;
  cursor: pointer;
  z-index: 99;
}

.ant-layout-sider .ant-menu:first-of-type {
  margin-top: 20px !important;
}

.ant-layout-sider .ant-menu.ant-menu-dark {
  background: var(--darkblue);
}

.ant-layout-sider .ant-menu-sub {
  background: #fff !important;
  margin: 0px 10px;
  border-radius: 5px !important;
}

.ant-layout-sider .ant-menu-sub .ant-menu-item {
  color: #000 !important;
  padding-left: 30px !important;
}

.ant-layout-sider .ant-menu-sub:hover .ant-menu-item {
  color: #000 !important;
}

.ant-layout-sider .ant-menu-submenu-title {
  color: #fff;
}

.ant-menu.ant-menu-dark .ant-menu-item {
  height: 45px;
  font-size: 1.05em;
  display: flex;
  align-items: center;
  color: #fff;
}

.ant-menu.ant-menu-dark .ant-menu-item:hover {
  color: #eee !important;
}

.ant-menu.ant-menu-dark .ant-menu-item:active {
  background: var(--blue) !important;
  color: #eee !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background: var(--blue);
  color: #fff;
}

.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-item-selected {
  background: var(--blue);
  color: #fff !important;
}

.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-item:hover {
  background: var(--blue);
  color: #fff !important;
}

.ant-menu .ant-menu-item svg {
  font-size: 1.05em !important;
}

.ant-layout-content {
  margin: 18px 18px 0;
  padding: 10px 24px;
  min-height: calc((100vh - 82px));
  overflow: auto;
  background: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

.ant-layout-content .ant-breadcrumb {
  margin-bottom: 10px;
}

.ant-layout-content .ant-breadcrumb ol li:not(:last-child) {
  color: #999;
}

/* table */
.ant-table-wrapper {
  margin-top: 20px;
}

.ant-table {
  border: 1px solid #ccc;
}

th.ant-table-cell {
  background: #f5f5f5 !important;
  border-bottom: 1px solid #eaeaea !important;
}

td.ant-table-cell {
  font-size: 0.9em;
  border-bottom: 1px solid #eaeaea !important;
}

.ant-drawer-content .ant-drawer-header {
  padding: 22px 24px;
  border-bottom: 1px solid #ddd;
}

/* form */
.ant-form-item {
  width: 100%;
}

.ant-picker {
  width: 100%;
}
